




















































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';

import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

@Component
export default class Home extends Vue {
  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ($('.testimonials-slider') as any).owlCarousel({
      items: 1,
      dots: true,
    });
  }
}
