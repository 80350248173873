import { db } from '@/config/firebase';

const getById = async (id: string): Promise<string | null> => {
  const document = await db.songs.doc(id).get();
  const data = document.data();

  if (!data) {
    return null;
  }

  return data.songPath;
};

export default {
  getById,
};
