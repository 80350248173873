import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Home from '@/views/Home.vue';
import Library from '@/views/Library.vue';
import AlbumDetail from '@/views/AlbumDetail.vue';
import ArtistDetail from '@/views/ArtistDetail.vue';
import SongDetail from '@/views/SongDetail.vue';
import { CollectionType } from '@/enums/Type';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
  },
  {
    path: '/library',
    name: 'collections',
    redirect: { name: 'library', params: { letter: 'a', type: CollectionType.ARTIST } },
  },
  {
    path: '/library/:letter/:type',
    name: 'library',
    component: Library,
    props: (route) => ({ letter: route.params.letter, type: route.params.type }),
  },
  {
    path: '/artist/:slug',
    name: 'artist-detail',
    component: ArtistDetail,
  },
  {
    path: '/album/:slug',
    name: 'album-detail',
    component: AlbumDetail,
  },
  {
    path: '/song/:slug',
    name: 'song-detail',
    component: SongDetail,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ selector: 'body', behavior: 'smooth' }),
});

export default router;
