





















import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';

import { PAUSE_SONG, RESUME_SONG } from '@/constants/actions/player';
import { GET_STATUS } from '@/constants/getters/player';
import { PLAYER } from '@/constants/modules';

import { PlayerStatus } from '@/enums/status';

@Component({
  computed: {
    ...mapGetters(PLAYER, { playerStatus: GET_STATUS }),
  },
})
export default class Play extends Vue {
  $refs!: {
    playButton: HTMLElement,
  };

  private playerStatus!: PlayerStatus;

  get isPlaying(): boolean {
    return this.playerStatus === PlayerStatus.PLAYING;
  }

  toggleSong(): void {
    this.$refs.playButton.blur();

    if (!this.isPlaying) {
      this.$store.dispatch(`${PLAYER}/${RESUME_SONG}`);
      return;
    }

    this.$store.dispatch(`${PLAYER}/${PAUSE_SONG}`);
  }
}
