


















































import { Component, Vue } from 'vue-property-decorator';
import { Howl } from 'howler';
import { MutationPayload } from 'vuex';

import Play from '@/components/player/Play.vue';
import SongProgress from '@/components/player/SongProgress.vue';
import Volume from '@/components/player/Volume.vue';

import { END_SONG, SET_PLAYING } from '@/constants/actions/player';
import { PLAYER } from '@/constants/modules';
import { SET_SONG_URL, SET_STATUS } from '@/constants/mutations/player';

import { PlayerStatus } from '@/enums/status';

@Component({
  components: {
    Play,
    SongProgress,
    Volume,
  },
})
export default class Player extends Vue {
  private sound: Howl | null = null;

  get soundDuration(): number {
    return this.sound?.duration() || 0;
  }

  created(): void {
    this.$store.subscribe(({ type, payload }: MutationPayload) => {
      switch (type) {
        case `${PLAYER}/${SET_SONG_URL}`:
          this.initHowler(payload);
          break;

        case `${PLAYER}/${SET_STATUS}`:
          this.updateSong(payload);
          break;

        default:
          break;
      }
    });
  }

  mounted():void {
    const bodyElement = document.querySelector('body') as HTMLElement;
    bodyElement.classList.add('player-active');
  }

  initHowler(songUrl: string): void {
    this.sound = new Howl({
      src: [songUrl],
      html5: true,
      onend: () => {
        this.$store.dispatch(`${PLAYER}/${END_SONG}`);
      },
    });

    this.sound.play();
    this.$store.dispatch(`${PLAYER}/${SET_PLAYING}`);
  }

  getSeek(): number {
    return this.sound?.seek() || 0;
  }

  setSeek(time: number): void {
    this.sound.seek(time);
  }

  setVolume(level: number): void {
    this.sound.volume(level);
  }

  updateSong(state: PlayerStatus): void {
    if (!this.sound) {
      return;
    }

    if (state === PlayerStatus.RESTART) {
      this.setSeek(0);
      this.$store.dispatch(`${PLAYER}/${SET_PLAYING}`);
      return;
    }

    if (state === PlayerStatus.PAUSED && this.sound.playing()) {
      this.sound.pause();
      return;
    }

    if (state === PlayerStatus.PLAYING && !this.sound.playing()) {
      this.sound.play();
    }
  }
}
