

















import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import Footer from '@/components/layout/Footer.vue';
import Header from '@/components/layout/Header.vue';
import Player from '@/components/player/Player.vue';

import { IS_PLAYER_ENABLE } from '@/constants/getters/player';
import { PLAYER } from '@/constants/modules';

@Component({
  components: {
    Footer,
    Header,
    Player,
  },
  computed: {
    ...mapGetters(PLAYER, { showPlayer: IS_PLAYER_ENABLE }),
  },
})
export default class App extends Vue {
  protected showPlayer!: boolean;

  protected prevHeight = 0;

  beforeLeaveHandler(element: HTMLElement): void {
    this.prevHeight = Number(getComputedStyle(element).height);
  }

  enterHandler(element: HTMLElement): void {
    const page = element;
    const { height } = getComputedStyle(element);

    page.style.height = `${this.prevHeight}px`;

    setTimeout(() => {
      page.style.height = height;
    });
  }

  afterEnterHandler(element: HTMLElement): void {
    const page = element;
    page.style.height = 'auto';
  }
}
