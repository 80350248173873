

























import { mapGetters, MutationPayload } from 'vuex';
import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';

import { GET_STATUS } from '@/constants/getters/player';
import { PLAYER } from '@/constants/modules';
import { SET_STATUS } from '@/constants/mutations/player';

import { PlayerStatus } from '@/enums/status';

@Component({
  filters: {
    secondsToTime(value: number, revert = false) {
      const roundedValue = Math.round(value);
      const minutes = Math.floor(roundedValue / 60) || 0;
      const seconds = roundedValue - minutes * 60 || 0;

      return `${revert ? '-' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`.trim();
    },
  },
  computed: {
    ...mapGetters(PLAYER, { playerStatus: GET_STATUS }),
  },
})
export default class SongProgress extends Vue {
  $refs!: {
    progressContent: HTMLElement,
  }

  @Prop({ required: true }) readonly duration!: number;

  @Prop({ required: true }) readonly currentSeek!: CallableFunction;

  private isDragging = false;

  private progress = 0;

  private currentTime = 0;

  private revertDuration = false;

  private playerStatus!: PlayerStatus;

  get isPlaying(): boolean {
    return this.playerStatus === PlayerStatus.PLAYING;
  }

  @Emit('seek')
  emitNewSeek(time: number): number {
    return time;
  }

  created(): void {
    this.$store.subscribe(({ type, payload }: MutationPayload) => {
      if (type === `${PLAYER}/${SET_STATUS}`) {
        if (payload === PlayerStatus.PLAYING) {
          requestAnimationFrame(this.step);
        }
      }
    });
  }

  toggleDuration(): void {
    this.revertDuration = !this.revertDuration;
  }

  updateTimerProgress(percent: number):void {
    this.currentTime = Math.round(percent);
    this.progress = ((percent / this.duration) * 100) || 0;
  }

  step(): void {
    if (!this.isDragging) {
      this.updateTimerProgress(this.currentSeek());
    }

    // Sound is still playing, continue stepping.
    if (this.isPlaying) {
      requestAnimationFrame(this.step);
    }
  }

  movePointer(event: Event, el: HTMLElement | null = null): void {
    // progress bar point half width
    let element = event.currentTarget as HTMLElement;

    if (el) {
      element = el;
    }

    const mouseXPosition = (event as MouseEvent).clientX;
    const progressDOMPosition = element.getBoundingClientRect().left;
    const progressWidth = element.offsetWidth;
    const percent = (mouseXPosition - progressDOMPosition) / progressWidth;
    const newDuration = this.duration * percent;

    if (newDuration < 0 || newDuration > this.duration) {
      return;
    }

    if (!this.isDragging) {
      this.emitNewSeek(newDuration);
    } else {
      window.requestAnimationFrame(() => this.updateTimerProgress(newDuration));
    }

    if (!this.isPlaying) {
      this.step();
    }
  }

  toggleDragState(): void {
    this.isDragging = !this.isDragging;

    const body = document.querySelector('body');
    (body as HTMLElement).classList.toggle('user-select-none');
  }

  startDragging(event: MouseEvent):void {
    this.toggleDragState();
    this.movePointer(event);

    window.addEventListener('mousemove', this.draggPointer, true);
    window.addEventListener('mouseup', this.stopDragging, true);
  }

  draggPointer(event: MouseEvent): void {
    this.movePointer(event, this.$refs.progressContent);
  }

  stopDragging(event: MouseEvent): void {
    this.toggleDragState();
    this.movePointer(event, this.$refs.progressContent);

    window.removeEventListener('mousemove', this.draggPointer, true);
    window.removeEventListener('mouseup', this.stopDragging, true);
  }
}
