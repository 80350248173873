

























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import AlbumItem from '@/components/AlbumItem.vue';

@Component({
  components: {
    AlbumItem,
  },
})
export default class ArtistDetail extends Vue {
  private songs: Array<string> = [
    'Son tus Perjumenes Mujer',
    'El Cristo de Palacagüina',
    'Clodomiro el Ñajo',
    'Alforja Campesina',
    'Credo',
    'La tumba del guerrillero',
    'Gloria',
    'Canto de Entrada',
    'Canto de Meditación',
    'Ofertorio',
  ];
}
