





























import { Component, Vue, Prop } from 'vue-property-decorator';
import IAlbumList from '@/models/Album/AlbumList';

@Component
export default class AlbumGrid extends Vue {
    @Prop({ required: true }) readonly albums!: IAlbumList;
}
