export enum PlayerStatus {
  EMPTY = 'empty',
  FETCHING = 'fetching',
  LOADED = 'loaded',
  PLAYING = 'playing',
  PAUSED = 'paused',
  RESTART = 'restart',
  STOPPED = 'stopped',
  ENDED = 'ended',
}

export default {
  PlayerStatus,
};
