import { CollectionType } from '@/enums/Type';
import { db } from '@/config/firebase';

const getUrl = async (path: string): Promise<string> => {
  const url = await db.storage.ref(path).getDownloadURL();

  return url;
};

const uploadFile = async (file: File, collectionType: CollectionType): Promise<string> => {
  const { type } = file;
  const [documentType] = type.split('/');
  const name = `${new Date().getTime()}.${file.name.split('.')[1]}`;
  const fullPath = `${documentType}/${collectionType}/${name}`;

  const fileData = await db.storage.ref(fullPath).put(file as Blob);
  await fileData.ref.getDownloadURL();

  return fullPath;
};

export default {
  getUrl,
  uploadFile,
};
