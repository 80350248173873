





















import { Component, Vue, Prop } from 'vue-property-decorator';
import IArtistList from '@/models/Artist/ArtistList';

@Component
export default class ArtistGrid extends Vue {
    @Prop({ required: true }) readonly artists!: IArtistList;
}
