











































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AlbumItem extends Vue {
  @Prop({ required: true }) private id!: string;

  @Prop({ default: true }) private showArtistInfo!: boolean;

  private songs: Array<string> = [
    'Son tus Perjumenes Mujer',
    'El Cristo de Palacagüina',
    'Clodomiro el Ñajo',
    'Alforja Campesina',
    'Credo',
    'La tumba del guerrillero',
    'Gloria',
    'Canto de Entrada',
    'Canto de Meditación',
    'Ofertorio',
  ];

  public randomSongTime(): string {
    const minutes = Math.floor(Math.random() * 5);
    const seconds = `${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}`;
    return `${minutes}:${seconds}`;
  }
}
