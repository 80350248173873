// eslint-disable-next-line import/prefer-default-export
export enum CollectionType {
  ARTIST = 'artist',
  ALBUM = 'album',
  SONG = 'song',
}

export enum NotificationType {
  SHOW = 'show',
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum NotificationTypeIcon {
  show = 'bell',
  info = 'info-circle',
  success = 'check-circle',
  error = 'times-circle',
}
