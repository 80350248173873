import { ActionContext } from 'vuex';

import PlayerState from '@/models/States/PlayerState';
import RootState from '@/models/States/RootState';

import {
  FETCH_SONG,
  PLAY_SONG,
  PAUSE_SONG,
  RESUME_SONG,
  STOP_SONG,
  SET_PLAYING,
  END_SONG,
} from '@/constants/actions/player';
import { GET_STATUS, GET_SONG_ID, IS_PLAYER_ENABLE } from '@/constants/getters/player';
import { INIT_STATE, SET_STATUS, SET_SONG_URL } from '@/constants/mutations/player';

import songAPI from '@/api/song';
import storageAPI from '@/api/storage';
import { PlayerStatus } from '@/enums/status';

const module = {
  namespaced: true,
  state: (): PlayerState => ({
    id: '',
    type: undefined,
    status: PlayerStatus.EMPTY,
    showPlayer: false,
    songUrl: '',
  }),
  mutations: {
    [INIT_STATE](state: PlayerState, id: string): void {
      state.id = id;
      state.showPlayer = true;
    },
    [SET_STATUS](state: PlayerState, status: PlayerStatus): void {
      state.status = status;
    },
    [SET_SONG_URL](state: PlayerState, songUrl: string):void {
      state.songUrl = songUrl;
    },
  },
  actions: {
    async [PLAY_SONG](
      { commit, state, dispatch }: ActionContext<PlayerState, RootState>,
      songId: string,
    ): Promise<void> {
      if (state.id) {
        commit(SET_STATUS, PlayerStatus.RESTART);
      } else {
        commit(INIT_STATE, songId);
        await dispatch(FETCH_SONG, songId);
      }
    },
    [PAUSE_SONG]({ commit }: ActionContext<PlayerState, RootState>): void {
      commit(SET_STATUS, PlayerStatus.PAUSED);
    },
    [RESUME_SONG]({ dispatch }: ActionContext<PlayerState, RootState>): void {
      dispatch(SET_PLAYING);
    },
    [STOP_SONG]({ commit }: ActionContext<PlayerState, RootState>): void {
      commit(SET_STATUS, PlayerStatus.STOPPED);
    },
    [END_SONG]({ commit }: ActionContext<PlayerState, RootState>): void {
      commit(SET_STATUS, PlayerStatus.ENDED);
    },
    async [FETCH_SONG](
      { commit }: ActionContext<PlayerState, RootState>,
      songId: string,
    ): Promise<void> {
      commit(SET_STATUS, PlayerStatus.FETCHING);

      const songPath = await songAPI.getById(songId);

      if (songPath) {
        const songUrl = await storageAPI.getUrl(songPath);
        commit(SET_STATUS, PlayerStatus.LOADED);
        commit(SET_SONG_URL, songUrl);
      }
    },
    [SET_PLAYING]({ commit }: ActionContext<PlayerState, RootState>): void {
      commit(SET_STATUS, PlayerStatus.PLAYING);
    },
  },
  getters: {
    [GET_SONG_ID](state: PlayerState): string {
      return state.id;
    },
    [GET_STATUS](state: PlayerState): string | undefined {
      return state.status;
    },
    [IS_PLAYER_ENABLE](state: PlayerState): boolean {
      return state.showPlayer;
    },
  },
};

export default module;
