export const FETCH_SONG = 'fetchSong';
export const PLAY_SONG = 'playSong';
export const PAUSE_SONG = 'pauseSong';
export const RESUME_SONG = 'resumeSong';
export const SET_PLAYING = 'setPlaying';
export const STOP_SONG = 'stopSong';
export const END_SONG = 'endSong';

export default {
  FETCH_SONG,
  PLAY_SONG,
  PAUSE_SONG,
  RESUME_SONG,
  SET_PLAYING,
  STOP_SONG,
  END_SONG,
};
