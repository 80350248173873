import Vue from 'vue';
import wysiwyg from 'vue-wysiwyg';
import Toasted from 'vue-toasted';
import App from './App.vue';
import router from './router';
import store from './store';

import 'bootstrap';
import '@/assets/sass/main.scss';
import 'vue-wysiwyg/dist/vueWysiwyg.css';

const editorConfig = {
  hideModules: {
    code: true,
    image: true,
    table: true,
  },
};

Vue.config.productionTip = false;
Vue.use(wysiwyg, editorConfig);
Vue.use(Toasted, { iconPack: 'fontawesome' });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
