import Vue from 'vue';
import Vuex from 'vuex';

import RootState from '@/models/States/RootState';

import player from '@/store/player';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: <RootState>{
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    player,
  },
});
