



































import { Component, Emit, Vue } from 'vue-property-decorator';

@Component
export default class Volume extends Vue {
  $refs!: {
    muteButton: HTMLInputElement;
    volumeContent: HTMLInputElement;
  };

  private volume = 1;

  private isDragging = false;

  @Emit('volume')
  emitVolume(): number {
    return this.volume;
  }

  mute(): void {
    this.$refs.muteButton.blur();

    this.volume = this.volume ? 0 : 1;
    this.emitVolume();
  }

  movePointer(event: Event, el: HTMLElement | null = null): void {
    let element = event.currentTarget as HTMLElement;

    if (el) {
      element = el;
    }

    const mouseXPosition = (event as MouseEvent).clientX;
    const progressDOMPosition = element.getBoundingClientRect().left;
    const volumeWidth = element.offsetWidth;
    const level = (mouseXPosition - progressDOMPosition) / volumeWidth;

    if (level < 0 || level > 1) {
      return;
    }

    this.volume = level;
    this.emitVolume();
  }

  toggleDragState(): void {
    this.isDragging = !this.isDragging;

    const body = document.querySelector('body');
    (body as HTMLElement).classList.toggle('user-select-none');
  }

  startDragging(event: MouseEvent): void {
    this.toggleDragState();
    this.movePointer(event);

    window.addEventListener('mousemove', this.dragPointer, true);
    window.addEventListener('mouseup', this.stopDragging, true);
  }

  dragPointer(event: MouseEvent): void {
    this.movePointer(event, this.$refs.volumeContent);
  }

  stopDragging(event: MouseEvent): void {
    this.toggleDragState();
    this.movePointer(event, this.$refs.volumeContent);

    window.removeEventListener('mousemove', this.dragPointer, true);
    window.removeEventListener('mouseup', this.stopDragging, true);
  }
}
