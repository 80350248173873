










import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';
import INavigationItem from '@/models/NavigationItem';

@Component
export default class NavigationItemSpy extends Vue {
  @Prop({ required: true }) readonly item!: INavigationItem;

  // eslint-disable-next-line no-undef
  @Prop({ required: true }) readonly options!: IntersectionObserverInit;

  @Prop({ required: true }) readonly isActive!: boolean;

  private observer!: IntersectionObserver;

  @Emit('intersected')
  intersected(): string {
    return this.item.id;
  }

  mounted(): void {
    const section = document.getElementById(this.item.id);

    this.observer = new IntersectionObserver(this.itemSpy, this.options);
    this.observer.observe(section as Element);
  }

  beforeDestroyed(): void {
    this.observer.disconnect();
  }

  itemSpy(entries: IntersectionObserverEntry[]): void {
    const [currentEntry] = entries;

    if (currentEntry.isIntersecting) {
      this.intersected();
    }
  }

  scrollTo(): void {
    const topSpace = 20;
    const section = document.getElementById(this.item.id) as HTMLElement;
    const scrollPosition = section.getBoundingClientRect().top + window.scrollY - topSpace;

    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  }
}
