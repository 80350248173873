
































































import { Component, Vue, Prop } from 'vue-property-decorator';
import PlayerButton from '@/components/PlayerButton.vue';
import ISongList from '@/models/Song/SongList';
import { CollectionType } from '@/enums/Type';
import { mapGetters } from 'vuex';
import { PLAYER } from '@/constants/modules';
import { GET_SONG_ID } from '@/constants/getters/player';
import { PLAY_SONG } from '@/constants/actions/player';

@Component({
  components: {
    PlayerButton,
  },
  computed: {
    ...mapGetters(PLAYER, {
      songId: GET_SONG_ID,
    }),
  },
})
export default class SongGrid extends Vue {
  @Prop({ default: false }) readonly showOriginal!: boolean;

  @Prop({ required: true }) readonly songs!: ISongList;

  private songType = CollectionType.SONG;

  private songId!: string;

  isCurrentSong(song: ISongList): boolean {
    return this.songId === song.id;
  }

  playSong(song: ISongList): void {
    this.$store.dispatch(`${PLAYER}/${PLAY_SONG}`, song.id);
  }
}
