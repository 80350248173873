
















import { Component, Prop, Vue } from 'vue-property-decorator';
import NavigationItemSpy from '@/components/NavigationItemSpy.vue';
import INavigationItem from '@/models/NavigationItem';

@Component({
  components: {
    NavigationItemSpy,
  },
})
export default class SideBarNavigationSpy extends Vue {
  $refs!: {
    navigationSpy: HTMLInputElement;
  };

  @Prop({ required: true }) readonly rootObserverId!: string;

  @Prop({ required: true }) readonly navItems!: Array<INavigationItem>;

  private activeItem: string = this.navItems[0].id;

  // eslint-disable-next-line no-undef
  private observerOptions: IntersectionObserverInit = {
    root: document.getElementById(this.rootObserverId),
    rootMargin: '0px 0px -85% 0px',
    threshold: 0,
  };

  setActiveItem(id: string):void {
    this.activeItem = id;
  }
}
