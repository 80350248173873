



























































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import AlbumGrid from '@/components/AlbumGrid.vue';
import ArtistGrid from '@/components/ArtistGrid.vue';
import SongGrid from '@/components/SongGrid.vue';

import IAlbumList from '@/models/Album/AlbumList';
import IArtistList from '@/models/Artist/ArtistList';
import ISongList from '@/models/Song/SongList';

import { CollectionType } from '@/enums/Type';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import artistAvatar from '@/assets/img/artist-avatar.jpg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import albumAvatar from '@/assets/img/album-cover.jpeg';

@Component({
  components: {
    AlbumGrid,
    ArtistGrid,
    SongGrid,
  },
})
export default class Library extends Vue {
  $refs!: {
    stickyAlphabet: HTMLInputElement;
  };

  @Prop({ required: true }) letter;

  @Prop({ required: true }) type;

  @Watch('letter', { immediate: true })
  onLetterChange(): void {
    this.getData();
  }

  @Watch('type', { immediate: true })
  onCollectionTypeChange(): void {
    this.getData();
  }

  private alphabet: Array<string> = 'abcdefghijklmnopqrstuvwxyz'.split('');

  private collectionTypes = CollectionType;

  private isFetching = false;

  private navigation = [
    {
      id: CollectionType.ARTIST,
      title: 'Artistas',
    },
    {
      id: CollectionType.ALBUM,
      title: 'Álbumes',
    },
    {
      id: CollectionType.SONG,
      title: 'Canciones',
    },
  ];

  private artists: Array<IArtistList> = [
    {
      id: '1',
      name: 'Camilo Zapata',
      image: artistAvatar,
    },
    {
      id: '2',
      name: 'Carlos Mejía Godoy',
      image: artistAvatar,
    },
    {
      id: '3',
      name: 'Erwin Krüger',
      image: artistAvatar,
    },
    {
      id: '4',
      name: 'Felipe Urrutia',
      image: artistAvatar,
    },
    {
      id: '5',
      name: 'Juan Solorzano',
      image: artistAvatar,
    },
    {
      id: '6',
      name: 'María Esther López',
      image: artistAvatar,
    },
  ];

  private albums: Array<IAlbumList> = [
    {
      id: '5',
      name: 'Cancionero Navideño',
      image: albumAvatar,
      artist: {
        id: '1',
        name: 'Juan Solorzano',
      },
    },
    {
      id: '4',
      name: 'Días de Amar',
      image: albumAvatar,
      artist: {
        id: '1',
        name: 'Dúo Guardabarranco',
      },
    },
    {
      id: '1',
      name: 'Mi Querido Viejo',
      image: albumAvatar,
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
    },
    {
      id: '2',
      name: 'Nicaragua en mi Sazón',
      image: albumAvatar,
      artist: {
        id: '1',
        name: 'María Esther López',
      },
    },
    {
      id: '3',
      name: 'Yo te amo Nicaragua',
      image: albumAvatar,
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
    },
  ];

  private songs: Array<ISongList> = [
    {
      id: '1',
      name: 'Son tus Perjumenes Mujer',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '2',
      name: 'El Cristo de Palacagüina',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '3',
      name: 'Clodomiro el Ñajo',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '23ZyPyI9RSFQfSR67UsA',
      name: 'El Nacatamal',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'María Esther López',
      },
      album: {
        id: '1',
        name: 'Nicaragua en mi Sazón',
      },
      isOriginal: false,
    },
    {
      id: '5',
      name: 'El Cristo de Palacagüina',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '6',
      name: 'El Cristo de Palacagüina',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '7',
      name: 'El Cristo de Palacagüina',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '8',
      name: 'El Cristo de Palacagüina',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '9',
      name: 'El Cristo de Palacagüina',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '10',
      name: 'El Cristo de Palacagüina',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '11',
      name: 'El Cristo de Palacagüina',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
  ];

  mounted(): void {
    window.addEventListener('scroll', this.toggleAlphabetShadow);
  }

  beforeDestroy(): void {
    window.removeEventListener('scroll', this.toggleAlphabetShadow);
  }

  toggleAlphabetShadow(): void {
    if (this.$refs.stickyAlphabet.offsetTop > 0) {
      this.$refs.stickyAlphabet.classList.add('shadow');
    } else {
      this.$refs.stickyAlphabet.classList.remove('shadow');
    }
  }

  pushState(letter: string, type: string = this.type): void {
    this.$router.push({ name: 'library', params: { letter, type } });
  }

  getData(): void {
    if (this.isFetching) {
      return;
    }

    this.isFetching = true;
    console.log('Fetching...', this.letter, this.type);
    // TODO: Add logic here to fetch data

    setTimeout(() => {
      this.isFetching = false;
    }, 500);
  }

  randomSongTime(): string {
    const minutes = Math.floor(Math.random() * 5);
    const seconds = `${Math.floor(Math.random() * 10)}${Math.floor(
      Math.random() * 10,
    )}`;
    return `${minutes}:${seconds}`;
  }
}
