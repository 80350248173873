






import { Component, Vue } from 'vue-property-decorator';
import AlbumItem from '@/components/AlbumItem.vue';

@Component({
  components: {
    AlbumItem,
  },
})
export default class AlbumDetail extends Vue {}
