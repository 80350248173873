





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import { CollectionType } from '@/enums/Type';

import { PLAYER } from '@/constants/modules';
import { GET_STATUS, GET_SONG_ID } from '@/constants/getters/player';
import { PAUSE_SONG, PLAY_SONG, RESUME_SONG } from '@/constants/actions/player';
import { PlayerStatus } from '@/enums/status';

@Component({
  computed: {
    ...mapGetters(PLAYER, {
      playerStatus: GET_STATUS,
      songId: GET_SONG_ID,
    }),
  },
})
export default class PlayerButton extends Vue {
  $refs!: {
    playerButton: HTMLElement;
  };

  @Prop({ required: true }) readonly id!: string;

  @Prop({ required: true }) readonly type!: CollectionType;

  private playerStatus!: PlayerStatus;

  private songId!: string;

  get isCurrentSong(): boolean {
    return this.songId === this.id;
  }

  get isPlaying(): boolean {
    return this.isCurrentSong && this.playerStatus === PlayerStatus.PLAYING;
  }

  play(): void {
    this.$refs.playerButton.blur();

    if (this.playerStatus === PlayerStatus.EMPTY) {
      this.$store.dispatch(`${PLAYER}/${PLAY_SONG}`, this.id);
      return;
    }

    if (this.id !== this.songId) {
      return;
    }

    if (this.playerStatus === PlayerStatus.PLAYING) {
      this.$store.dispatch(`${PLAYER}/${PAUSE_SONG}`, this.id);
    } else {
      this.$store.dispatch(`${PLAYER}/${RESUME_SONG}`, this.id);
    }
  }
}
