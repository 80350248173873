
































































































import { Component, Vue } from 'vue-property-decorator';

import AlbumGrid from '@/components/AlbumGrid.vue';
import SideBarNavigationSpy from '@/components/SideBarNavigationSpy.vue';
import SongGrid from '@/components/SongGrid.vue';

import IAlbumList from '@/models/Album/AlbumList';
import INavigationItem from '@/models/NavigationItem';
import ISongList from '@/models/Song/SongList';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import albumAvatar from '@/assets/img/album-cover.jpeg';

@Component({
  components: {
    AlbumGrid,
    SideBarNavigationSpy,
    SongGrid,
  },
})
export default class SongDetail extends Vue {
  private navItems: Array<INavigationItem> = [
    {
      id: 'information',
      title: 'Información',
    },
    {
      id: 'history',
      title: 'Historia',
    },
    {
      id: 'albums',
      title: 'Álbumes',
    },
    {
      id: 'lyrics',
      title: 'Letra',
    },
    {
      id: 'covers',
      title: 'Versiones',
    },
  ];

  private albums: Array<IAlbumList> = [
    {
      id: '5',
      name: 'Cancionero Navideño',
      image: albumAvatar,
      artist: {
        id: '1',
        name: 'Juan Solorzano',
      },
    },
    {
      id: '4',
      name: 'Días de Amar',
      image: albumAvatar,
      artist: {
        id: '1',
        name: 'Dúo Guardabarranco',
      },
    },
    {
      id: '1',
      name: 'Mi Querido Viejo',
      image: albumAvatar,
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
    },
    {
      id: '2',
      name: 'Nicaragua en mi Sazón',
      image: albumAvatar,
      artist: {
        id: '1',
        name: 'María Esther López',
      },
    },
    {
      id: '3',
      name: 'Yo te amo Nicaragua',
      image: albumAvatar,
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
    },
  ];

  private songs: Array<ISongList> = [
    {
      id: '1',
      name: 'Yo te Amo Nicaragua',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Cuneta Son Machin',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '2',
      name: 'Yo te Amo Nicaragua',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Dúo Guardabarranco',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '3',
      name: 'Yo te Amo Nicaragua',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Perrozompopo',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '4',
      name: 'Yo te Amo Nicaragua',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'María Esther López',
      },
      album: {
        id: '1',
        name: 'Nicaragua en mi Sazón',
      },
      isOriginal: false,
    },
    {
      id: '5',
      name: 'Yo te Amo Nicaragua',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Manifiesto Urbano',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '6',
      name: 'Yo te Amo Nicaragua',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Los Juglares',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '7',
      name: 'Yo te Amo Nicaragua',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: true,
    },
    {
      id: '8',
      name: 'Yo te Amo Nicaragua',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '9',
      name: 'Yo te Amo Nicaragua',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '10',
      name: 'Yo te Amo Nicaragua',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
    {
      id: '11',
      name: 'Yo te Amo Nicaragua',
      duration: this.randomSongTime(),
      artist: {
        id: '1',
        name: 'Carlos Mejía Godoy',
      },
      album: {
        id: '1',
        name: 'Mi Querido Viejo',
      },
      isOriginal: false,
    },
  ];

  randomSongTime(): string {
    const minutes = Math.floor(Math.random() * 5);
    const seconds = `${Math.floor(Math.random() * 10)}${Math.floor(
      Math.random() * 10,
    )}`;
    return `${minutes}:${seconds}`;
  }
}
